
import { defineComponent } from "vue";
import fetchGet from "@/fetchGet";
import fetchPut from "@/fetchPut";
import fetchDelete from "@/fetchDelete";

export default defineComponent({
  name: "WeekTable",
  props: {
    week: Number,
    year: Number,
  },
  data() {
    return {
      users: [],
      days: [],
      presences: {},
      absences: {},
      verticalDisplay: false,
      timer: 0,
      longpress: false,
    };
  },
  watch: {
    week(newWeek, oldWeek) {
      this.loadWeek();
    },
  },
  created() {
    fetchGet(`/users`).then((res) => {
      if (res.ok) {
        res.json().then((u) => {
          this.users = u;
          this.loadWeek();
        });
      }
    });

    this.verticalDisplay = window.innerWidth < window.innerHeight;
  },

  methods: {
    loadWeek() {
      const weekYear = {
        week: this.week,
        year: this.year,
      };
      fetchGet(`/days`, weekYear).then((res) => {
        if (res.ok) {
          res.json().then((u) => {
            this.days = u;
            this.loadPresences();
          });
        }
      });
    },
    loadPresences() {
      let weekYear = {
        week: this.week,
        year: this.year,
        isPresent: true,
      };
      fetchGet(`/presences-by-week`, weekYear).then((res) => {
        if (res.ok) {
          res.json().then((u) => {
            this.presences = u;
          });
        }
      });
      weekYear.isPresent = false;
      fetchGet(`/presences-by-week`, weekYear).then((res) => {
        if (res.ok) {
          res.json().then((u) => {
            this.absences = u;
          });
        }
      });
    },
    toggle(date: string, user: any) {
      if (!this.longpress) {
        this.cancelTimer();
        let pres: { [index: string]: string[] } = this.presences;
        let usersThisDay: string[] = pres[date];
        let uri = `/presences/${user.trigramme}/${date}`;
        if (!usersThisDay || usersThisDay.indexOf(user.trigramme) == -1) {
          uri = uri + "/true";
        } else {
          uri = uri + "/false";
        }
        fetchPut(uri).then(this.loadPresences);
      } else {
        this.longpress = false;
      }
    },
    delete(date: string, user: any) {
      fetchDelete(`/presences/${user.trigramme}/${date}`).then(
        this.loadPresences
      );
    },
    toggleWeek(user: any) {
      let noneMissing = true;
      let pres: { [index: string]: string[] } = this.presences;
      for (let i = 0; i < this.days.length; i++) {
        let date = this.days[i];
        let usersThisDay: string[] = pres[date];

        if (!usersThisDay || usersThisDay.indexOf(user.trigramme) == -1) {
          noneMissing = false;
        }
      }
      let uri = `/presences/${user.trigramme}/${this.year}/${this.week}`;
      if (noneMissing) {
        uri = uri + "/false";
      } else {
        uri = uri + "/true";
      }
      fetchPut(uri).then(this.loadPresences);
    },
    countUsers(date: string) {
      let pres: { [index: string]: string[] } = this.presences;
      const trigrammes = pres[date];
      let result = 0;
      if (trigrammes) {
        this.users.forEach((user: any) => {
          if (trigrammes.indexOf(user.trigramme) != -1) {
            result++;
          }
        });
      }
      return result;
    },
    listUsers(date: string) {
      let pres: { [index: string]: string[] } = this.presences;
      const trigrammes = pres[date];
      const list: string[] = [];
      if (trigrammes) {
        this.users.forEach((user: any) => {
          if (trigrammes.indexOf(user.trigramme) != -1) {
            list.push(user.firstName);
          }
        });
        return list.join(", ");
      }
      return "Personne !";
    },
    alertWithUsersList(date: string) {
      alert(this.listUsers(date));
    },
    formatDay(dateString: string) {
      const daysOfWeek = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
      const months = [
        "jan",
        "fév",
        "mar",
        "avr",
        "mai",
        "juin",
        "juil",
        "aou",
        "sep",
        "oct",
        "nov",
        "déc",
      ];
      const date = new Date(dateString);
      const parts = [];
      parts.push(daysOfWeek[date.getUTCDay()]);
      parts.push(date.getDate());
      parts.push(months[date.getMonth()]);
      return parts.join(" ");
    },
    startTimer(date: string, user: any) {
      if (this.timer === 0) {
        this.timer = setTimeout(() => {
          this.longpress = true;
          this.delete(date, user);
        }, 500);
      }
    },
    cancelTimer() {
      if (this.timer !== 0) {
        clearTimeout(this.timer);
        this.timer = 0;
      }
    },
  },
});
