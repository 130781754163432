import { BACKEND } from '@/Constants'

export default async (uri: string, body: any = {}, params?: any):Promise<Response> => {
  const headers = new Headers({
    Accept: 'application/json',
    "Content-Type": 'application/json'
  })

  const init: RequestInit = {
    method: 'PUT',
    headers: headers
  }

  const url = new URL(`${BACKEND}${uri}`)
  if (params) {
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]))
  }
  if (body) {
    init.body = JSON.stringify(body);
  }
  const result = await fetch(url.toString(), init)
  return result
}
