
import { defineComponent } from 'vue';
import fetchGet from '@/fetchGet'

export default defineComponent({
  name: 'HomeView',
  created() {
    fetchGet(`/now`).then((res) => {
      if (res.ok) {
        res.json().then((now) => {
          this.$router.push(`/week/${now.year}/${now.week}`);
        })
      }
    });
  }
});
