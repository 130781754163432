
import { defineComponent } from "vue";
import fetchGet from "@/fetchGet";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);

export default defineComponent({
  name: "YearView",
  components: {
    Line,
  },
  props: {
    year: Number,
  },
  data() {
    return {
      loaded: false,
      chartData: {
        labels: [""],
        datasets: [
          {
            label: "% Occupation",
            backgroundColor: "#ff6600",
            data: [0],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    year() {
      this.loadData();
    },
  },
  methods: {
    previous() {
      if (this.year) {
        this.$router.push(`/year/${this.year - 1}`);
      }
    },
    next() {
      if (this.year) {
        this.$router.push(`/year/${this.year + 1}`);
      }
    },
    loadData() {
      this.loaded = false;
      try {
        this.loadWeeks();
        this.loaded = true;
      } catch (e) {
        console.error(e);
      }
    },
    loadWeeks() {
      this.chartData.labels = [];
      this.chartData.datasets[0].data = [];
      for (let weekNb = 1; weekNb <= 53; weekNb++) {
        this.chartData.labels.push("" + weekNb);
        this.chartData.datasets[0].data.push(0);
        this.loadWeek(weekNb);
      }
    },
    loadWeek(week: number) {
      fetchGet(`/days`, {
        week,
        year: this.year,
      }).then((res) => {
        if (res.ok) {
          res.json().then((days) => {
            this.loadPresences(week, days.length);
          });
        }
      });
    },
    loadPresences(week: number, nbDays: number) {
      fetchGet(`/presences-by-week`, {
        week,
        year: this.year,
      }).then((res) => {
        if (res.ok) {
          res.json().then((presences) => {
            let sum = 0;
            Object.keys(presences).forEach(
              (day) => (sum += this.occupation(presences[day].length))
            );
            this.chartData.datasets[0].data[week - 1] = Math.round(sum / nbDays);
          });
        }
      });
    },
    occupation(total: number) {
      return Math.round((total / 19) * 100);
    },
  },
});
