import { BACKEND } from '@/Constants'

export default async (uri: string, params: any = {}):Promise<Response> => {
  const init: RequestInit = { method: 'GET' }
  const url = new URL(`${BACKEND}${uri}`)
  if (params) {
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]))
  }
  const result = await fetch(url.toString(), init)
  return result
}
