import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import propsParser from "vue-router-parse-props";

import HomeView from "../views/HomeView.vue";
import WeekView from "../views/WeekView.vue";
import YearView from "../views/YearView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/week/:year/:week",
    name: "week",
    component: WeekView,
    props: propsParser({
      week: Number,
      year: Number,
    }),
  },
  {
    path: "/year/:year",
    name: "year",
    component: YearView,
    props: propsParser({
      year: Number,
    }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
