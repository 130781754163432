
import { defineComponent } from 'vue';
import WeekTable from '@/components/WeekTable.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'WeekView',
  components: {
    WeekTable,
  },
  props: {
    week: Number,
    year: Number
  },
  methods: {
    previous() {
      if (this.week && this.year) {
        const previousWeek = this.week - 1;
        if (previousWeek == 0) {
          this.$router.push(`/week/${this.year - 1}/53`);
        } else {
          this.$router.push(`/week/${this.year}/${previousWeek}`);
        }
      }
    },
    next() {
      if (this.week && this.year) {
        const nextWeek = this.week + 1;
        if (nextWeek == 54) {
          this.$router.push(`/week/${this.year + 1}/1`);
        } else {
          this.$router.push(`/week/${this.year}/${nextWeek}`);
        }
      }
    }
  }

});
